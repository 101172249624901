import {createContext, useContext, useEffect, useMemo, useState} from "react";
import useLocalStorage from "./useLocalStorage";
import {useLocation, useNavigate} from "react-router";
import {getUserToken} from "../api/requests";

const UseAuth = createContext();

export const AuthProvider = ({children}) => {

    let location = useLocation();
    //const [user, setUser] = useLocalStorage("user", {});
    const [token, setToken] = useState(null)
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || "/";

    useEffect(()=>{
        const userToken = localStorage.getItem("token");

        if(!userToken || userToken === "null") {
            getUserToken()
                .then((res)=> {
                    localStorage.setItem("token", res.data.token)
                    setToken(res.data.token)
                    //setUser()
                }).catch((error)=>{
                    localStorage.setItem("token", null)
                });
        }
    })

    const login = async (data) => {
        //setUser(data);
        console.log(location.state?.from?.pathname)
        navigate(from, {replace: true});
    };


    const logout = () => {
        //setUser(null);
        //localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate("/bejelentkezes", {replace: true});
    };

    const value = useMemo(
        () => ({
            //user,
            token,
            login,
            logout
        }),
        //[user]
        [token]
    );

    return <UseAuth.Provider value={value}>{children}</UseAuth.Provider>;
};

export const useAuth = () => {
    return useContext(UseAuth);
};
