import React from 'react';

const Game = ({title, description, game}) => {
    return (
        <div>
            <h3>{title}</h3>
            <iframe src={game} width='100%' height='400px' frameBorder="0" className={'my-5'}/>
            <h4>{description}</h4>
        </div>
    );
};

export default Game;