import Game from "../../Components/Game/Game";

export const Days = [{
    id: 1, content:
        <>
            <h2>1. nap</h2>
            <div>
                <img src="assets/udvozlo_oldal.png" alt="" className={'my-4 rounded-lg'}/>
            </div>
        </>
},
    {
        id: 2, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Q_yuO8UNGmY"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Q_yuO8UNGmY"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 3, content:
            <>
                <h2>Mikulás keksz</h2>
                <div>
                    <img src="assets/mikulas-keksz.jpg" alt="" className={'my-4 rounded-lg'}/>
                </div>
                <h4>Tápláló finomság a Nagyszakállúnak a hosszú útra, na meg persze a gyerekeknek!
                    Van egy olyan szokás, amikor a gyerekek a Mikulásnak kikészítik az ablakba a kekszet és a tejet. Ez lehet az a bizonyos keksz, hiszen nagyon tápláló, nem száraz, így a Mikulásnak biztosan lesz energiája minden gyerek csizmájába ajándékot rejteni.</h4>
                <div className="grid md:grid-cols-5 gap-x-2">
                    <div className={'md:col-span-2'}>
                        <h3>Hozzávalók</h3>
                        <p>18 darabhoz</p>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>10 - 12 db datolya (kb. 90 g)</li>
                            <li>90 g dió</li>
                            <li>4 g őrölt lenmag</li>
                            <li>½ tk vaníliaőrlemény</li>
                            <li>1 csipet só</li>
                            <li>1 ½ g szódabikarbóna</li>
                            <li>20 ml víz</li>
                            <li>1 csipet fahéj</li>
                            <li>2 ½ g almaborecet vagy 1 db tojás</li>
                            <li>40 g csokipasztilla (kihagyható)</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>Előkészítési idő: 20 perc | Sütési idő: 15 perc | Összesen: 35 perc</p>
                        <p>A mikulás keksz elkészítéséhez a datolyát 2 dl forró vízbe áztatjuk 15 percre. A diót ledaráljuk (ha a gyermek 3 éven aluli, egyáltalán ne legyen benne kis darab dió sem). A dió mellé, az aprítóba tesszük a megduzzadt datolyákat, a lenmagot, a vaníliát, a sót, a szódabikarbónát, a vizet, a fahéjat és az ecetet vagy tojást. Addig aprítjuk, amíg a tészta sima és ragadós nem lesz. A masszához keverjük a csokipasztillát, majd már csak fakanállal jól átkeverjük.
                        </p>
                        <p>Sütőpapírral bélelt tepsire kanalazzuk a masszát egy evőkanál vagy egy kisebb fagyiskanál segítségével, ujjainkkal kicsit lenyomkodjuk őket kekszformára. 180 fokra előmelegített sütőben 13-14 percig sütjük, amíg a szélük aranybarna nem lesz. Ha szeretnénk, a tetejére még tehetünk egy-egy csokipasztillát, rá fog olvadni. Sütés után hagyjuk őket teljesen kihűlni a tepsin, hiszen nagyon törékenyek még melegen.
                        </p>
                        <p><strong>TIPP:</strong> Vegán édesség, ha tojás helyett borecettel készítjük.</p>
                        <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/karacsony/karacsonyi-receptek-gyerekeknek/mikulas-keksz/" target={"_blank"}>https://streetkitchen.hu/karacsony/karacsonyi-receptek-gyerekeknek/mikulas-keksz/</a></p>
                    </div>
                </div>
            </>

    },
    {
        id: 4, content:
            <>
                <h2>Beszélgessetek :) Itt találsz néhány beszélgetős társasjáték ötletet </h2>
                <p className={'my-4 text-base lg:text-md'}>Tekintsd meg a gémklub összefoglalását kommunikációs társasjátékokból, hiszen mi sem fontosabb a mai világban, mint hogy megtanuljuk kifejezni saját, és megérteni mások gondolatait a kölcsönös elfogadás céljából. </p>
                <a href={"https://www.gemklub.hu/parti-tarsasjatekok/kommunikacios-tarsasjatekok"} target={"_blank"} className={'btn-primary mx-auto w-max'}>Megnézem</a>
            </>
    },
    {
        id: 5, content: <>
            <h2>5. nap</h2>
            <div>
                <a href="https://teams.microsoft.com/l/channel/19%3A065bee1b10e64344bf2aefc906ebec8d%40thread.tacv2/Minden ami motiv%C3%A1l?groupId=7b6bc839-d51a-4425-a8d6-eb83cf44d731&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78" target={"_blank"}><img width="575" src="assets/dec5.gif" alt={""} className={'my-4 rounded-lg hidden lg:block'} /></a>
                <a href="https://teams.microsoft.com/l/channel/19%3A065bee1b10e64344bf2aefc906ebec8d%40thread.tacv2/Minden ami motiv%C3%A1l?groupId=7b6bc839-d51a-4425-a8d6-eb83cf44d731&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78" target={"_blank"}><img src="assets/dec5-mob.gif" alt="" className={'my-4 lg:hidden'}/></a>
            </div>
        </>
    },
    {
        id: 6, content: <>
            <h2 className={'md:text-4xl'}>Ismered a WMN: Beszélnünk kell podcastját?</h2>
            <p className={'my-4 text-base lg:text-md'}>Emberekről. Kapcsolatokról. Magunkról.</p>
            <img src="assets/wmn-podcast.jpg" alt=""/>
            <a href={"https://youtube.com/playlist?list=PL0uY1N5xwplV54nxRs75A8fVJ0VIzQBnF&si=VXJFPdgJ32emjB90"} target={"_blank"} className={'btn-primary mx-auto w-max mt-4'}>Megnézem</a>
        </>
    },
    {
        id: 7, content: <>
            <h2>Karácsonyi vega pite</h2>
            <div>
                <img src="assets/vega-pite.jpg" alt="" className={'my-4 rounded-lg'}/>
            </div>
            <h4>Egy isteni vegetáriánus pite teljesen karácsonyra hangolva. A téli fűszerektől egészen különleges ízt kap, így ha feldobnátok valami újjal a karácsonyi asztalt, akkor ne habozzatok!</h4>
            <div className="grid md:grid-cols-5 gap-x-2">
                <div className={'md:col-span-2'}>
                    <h3>Hozzávalók</h3>
                    <p>1 23 cm átmérőjű piteformához</p>
                    <h4 className={'mb-2'}>A pitetésztához</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>400 g liszt</li>
                        <li>200 g vaj</li>
                        <li>200 g sajtkrém</li>
                        <li>2 mk só</li>
                        <li>160 g parmezán reszelve</li>
                        <li>kb. 100 ml hideg víz</li>
                    </ul>
                    <h4 className={'mb-2'}>A töltelékhez</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>2 db krumpli (2 db / 300 g)</li>
                        <li>só</li>
                        <li>4 - 5 ek olívaolaj</li>
                        <li>1 fej hagyma (1 fej / 150 g)</li>
                        <li>1 - 2 gerezd fokhagyma</li>
                        <li>2 csomag CREAVEG darált húshelyettesítő (200 g-os kiszerelés)</li>
                        <li>kb. 0,5 tk őrölt bors</li>
                        <li>½ tk őrölt fahéj</li>
                        <li>½ mk őrölt szegfűszeg</li>
                        <li>frissen reszelt szerecsendió</li>
                        <li>½ tk kakukkfű</li>
                        <li>3 - 4 levél friss zsálya</li>
                    </ul>
                    <h4 className={'mb-2'}>A tetejére</h4>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>1 db tojás</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A karácsonyi vega pite elkészítéséhez először a tésztával kezdünk, mert annak pihennie kell majd a hűtőben. A lisztet, a vajat, a sajtkrémet, a sót és a parmezánt robotgépben morzsásra dolgozzuk, majd fokozatosan annyi hideg vizet adunk hozzá, hogy összegyúrva tészta állagot kapjunk. Fóliába csomagoljuk, és a hűtőbe tesszük 30-40 percre.
                    </p>
                    <p>A töltelékhez a meghámozott krumplikat egészben, sós vízben megfőzzük. Egy másik edényben olívaolajat melegítünk, és rádobjuk az apróra vágott hagymát. Ha már megdinsztelődött, akkor jöhet rá a zúzott fokhagyma, majd a húspótló, végül az áttört krumpli. Összekeverjük, és mehet hozzá 1 tk só, valamint a fűszerek is, ezzel is alaposan elkeverjük, és adunk neki kb. 5 percet, majd levesszük a tűzről, és félretesszük.</p>
                    <p>Kettévágjuk a tésztát, és az egyiket kerek formára nyújtjuk, beletesszük a piteformába, és jöhet bele a töltelék. A tészta másik felét is kinyújtjuk, és szépen ráillesztjük a formára. A felesleges részeket levágjuk, a széleket alaposan összenyomkodjuk, majd a pite tetejét a felvert tojással megkenjük, és megszurkáljuk, hogy a hő távozni tudjon, és a pite ne dudorodjon fel.</p>
                    <p>180 fokra előmelegített sütőben 30-40 percig sütjük. Mikor elkészült, hagyjuk egy picit hűlni, és csak utána szeleteljük fel.</p>
                    <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/karacsony/vega-karacsony/karacsonyi-vega-pite/" target={"_blank"}>https://streetkitchen.hu/karacsony/vega-karacsony/karacsonyi-vega-pite/</a></p>
                </div>
            </div>
        </>
    },
    {
        id: 8, content: <>
            <h2>8. nap</h2>
            <div>
                <img src="assets/dec8.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/>
                <img src="assets/dec8-mob.jpg" alt="" className={'my-4 lg:hidden'}/>
            </div>
        </>
    },
    {
        id: 9, content: <>
            <h2>A Karácsony ízei püspökkenyérbe zárva</h2>
            <div>
                <img src="assets/puspokkenyer.jpg" alt="" className={'my-4 rounded-lg'}/>
            </div>
            <h4>A püspökkenyér tipikus karácsonyi süti és körülbelül 623 recept kering róla az interneten... :D Nem csoda, hiszen mindenki variál rajta valamit, de a lényeg, hogy egy alaprecepttel mindenképp jól jártok. Mi most mutatunk egyet, ami azon felül, hogy elképesztően finom, glutén-és laktózmentes is és hozzáadott cukrot sem tartalmaz!</h4>
            <div className="grid md:grid-cols-5 gap-x-2">
                <div className={'md:col-span-2'}>
                    <h3>Hozzávalók</h3>
                    <p>1 db őzgerincformához</p>
                    <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                        <li>600 g aszalt és kandírozott gyümölcs vegyesen (meggy, szilva, datolya, áfonya, mazsola, narancshéj, barack, gyömbér…)</li>
                        <li>50 g darált mogyoró</li>
                        <li>200 g darált mandula</li>
                        <li>3 db tojás</li>
                        <li>1 csipet só</li>
                        <li>1 mk őrölt fahéj</li>
                        <li>2 csipet őrölt szegfűszeg</li>
                        <li>2 csipet őrölt szerecsendió</li>
                        <li>25 ml olívaolaj</li>
                        <li>1 db narancs reszelt héja</li>
                        <li>1 db citrom reszelt héja</li>
                        <li>½ db vaníliarúd kikapart belseje</li>
                    </ul>
                </div>
                <div className={'md:col-span-3'}>
                    <p>A gyömölcsöket apróra vágjuk. Egy tálba rakjuk az összes hozzávalót, jól átkeverjük és egy kiolajozott, darált mandulával megszórt őzgerincformába simítjuk a masszát. 170 fokos sütőben 35-40 percig sütjük a püspökkenyeret.
                    </p>
                    <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/meguszos-sutik/karacsony-izei-puspokkenyerbe-zarva/" target={"_blank"}>https://streetkitchen.hu/meguszos-sutik/karacsony-izei-puspokkenyerbe-zarva/</a></p>
                </div>
            </div>
        </>
    },
    {
        id: 10, content: <>
            <h2 className={'md:text-4xl'}>Ismered az Éva magazin - Micsoda nők voltak podcastját?</h2>
            <p className={'my-4 text-base lg:text-md'}>A Micsoda nők voltak! az Éva Magazin podcast műsora, amelynek minden részében más-más olyan nőről fogunk beszélgetni, akik bár már nem élnek, de érdemes róluk beszélni, megismerni életüket, munkásságukat. Érdekes, szórakoztató köntösben, túl a száraz évszámokon. A műsorvezetők: Budai Lotti történelmi regényíró és Zubor Rozi, újságíró, az ÉvaMagazin.hu főszerkesztője.</p>
            <img src="assets/micsoda-nok-podcast.jpg" alt=""/>
            <a href={"https://www.youtube.com/playlist?list=PLd5zQijosOtgbsQK7BIGB4jSXqQC6jDfB"} target={"_blank"} className={'btn-primary mx-auto w-max mt-4'}>Megnézem</a>
        </>
    },
    {
        id: 11,
        content: <>
            <h2>11. nap</h2>
            <div>
                <img src="assets/dec11.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/>
                <img src="assets/dec11-mob.jpg" alt="" className={'my-4 lg:hidden'}/>
            </div>
        </>
    },
    {
        id: 12,
        content: <>
            <h2>12. nap</h2>
            <div>
                <a href="https://teams.microsoft.com/l/channel/19%3A065bee1b10e64344bf2aefc906ebec8d%40thread.tacv2/Minden ami motiv%C3%A1l?groupId=7b6bc839-d51a-4425-a8d6-eb83cf44d731&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78" target={"_blank"}><img width="575" src="assets/dec12.gif" alt={""} className={'my-4 rounded-lg hidden lg:block'} /></a>
                <a href="https://teams.microsoft.com/l/channel/19%3A065bee1b10e64344bf2aefc906ebec8d%40thread.tacv2/Minden ami motiv%C3%A1l?groupId=7b6bc839-d51a-4425-a8d6-eb83cf44d731&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78" target={"_blank"}><img src="assets/dec12-mob.gif" alt="" className={'my-4 lg:hidden'}/></a>
            </div>
        </>
    },
    {
        id: 13, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/iDicwfAhDaM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/iDicwfAhDaM"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    },
    {
        id: 14, content:
            <>
                <h2>14. nap</h2>
                <div>
                    <a href="https://ciposdoboz.hu/" target={"_blank"}><img src="assets/dec14.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/></a>
                    <a href="https://ciposdoboz.hu/" target={"_blank"}><img src="assets/dec14-mob.jpg" alt="" className={'my-4 lg:hidden'}/></a>
                </div>
            </>
    },
    {
        id: 15, content:
            <>
                <h2>15. nap</h2>
                <div>
                    <img src="assets/dec15.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/>
                    <img src="assets/dec15-mob.jpg" alt="" className={'my-4 lg:hidden'}/>
                </div>
            </>
    },
    {
        id: 16, content:
            <>
                <h2>Tojáslikőr</h2>
                <div>
                    <img src="assets/tojaslikor.jpg" alt="" className={'my-4 rounded-lg'}/>
                </div>
                <h4>Ez a tojáslikőr nagy sikert aratott a szerkesztőségben. Sokak szerint kicsit erősre sikerült, de mindenkinek nagyon ízlett. Ha nem akarjátok túl erősre, akkor a rumból kicsit tegyetek kevesebbet bele, de szerintem ennyi karácsonykor simán belefér. :D</h4>
                <div className="grid md:grid-cols-5 gap-x-2">
                    <div className={'md:col-span-2'}>
                        <h3>Hozzávalók</h3>
                        <p>körülbelül 1 literhez</p>
                        <ul className={'list-disc list-inside marker:text-brandSecondary'}>
                            <li>4 db tojássárgája</li>
                            <li>400 ml habtejszín</li>
                            <li>100 ml tej</li>
                            <li>1 csomag vaníliás cukor</li>
                            <li>3 ek cukor</li>
                            <li>1 mk őrölt fahéj</li>
                            <li>350 ml fehér rum</li>
                        </ul>
                    </div>
                    <div className={'md:col-span-3'}>
                        <p>Ahogy bejgli nélkül, úgy tojáslikőr nélkül sincs karácsony, legalábbis sok helyen elengedhetetlen kísérője az ünnepnek ez az ital. Érdekesség, hogy a tojáslikőr fogyasztásának karácsonyi hagyománya eredetileg az Egyesült Királyságból és az Egyesült Államokból ered. Nagyon sokan a boltban vásárolják, de házilag is legalább annyira ütős verzió készíthető. Szerencsére nem kell hozzá semmi spéci alapanyag, vagy nem kell órákig a konyhában álldogálni. Egyszerű, de ha egyszer megcsináljátok, sajnos hamar el tud fogyni.</p>
                        <h4 className={'mb-2'}>A recept:</h4>
                        <p>A tejszínt, a tejet, a tojássárgájákkal, a cukorral, a vaníliás cukorral és a fahéjjal összekeverjük. Ezután egy fém tálban vízgőz felett folyamatos kevergetés mellett besűrítjük úgy, hogy csak kb. 90 fokig melegítjük, mert ha felforr, akkor kicsapódhat a tejszín.</p>
                        <p>Ha besűrűsödött, akkor kicsit hűlni hagyjuk, és hozzáadjuk a rumot. Hűtőben tárolva két hétig simán tárolható, bár azalatt úgyis elfogy. Persze, azért csak mértékkel fogyasszátok, nehogy baj legyen belőle</p>
                        <p><strong>Forrás:</strong> <a href="https://streetkitchen.hu/italok/klasszikus-tojaslikor/" target={"_blank"}>https://streetkitchen.hu/italok/klasszikus-tojaslikor/</a></p>
                    </div>
                </div>
            </>
    },
    {
        id: 17, content:
            <>
               <h2>11 csodás téli kirándulóhely, ha magad mögött hagynád a város zaját</h2>
                <p><strong>Eleged van a vásári forgatagból, a zsúfolt jégpályákból és a januári szürkeségből? Hagyd magad mögött a város zaját, és vessz el a téli nyugalom birodalmában Magyarország legszebb téli kirándulóhelyein!</strong></p>
                <h3 className={'mt-2'}>Lillafüred</h3>
                <p>A Miskolchoz tartozó Lillafüred az év minden szakában mesebeli látványt kínál, de legszebb arcát télen, hóhulláskor mutatja. A fehér fák erdején áttörő Lillafüredi Palotaszálló előkelő sziluettje és a mellette elterülő, kis szerencsével fagyott felszínű Hámori-tó szemet kápráztató együttese valóban képeslapra kívánkozik, de érdemes legalább egy fél napot rászánni a környékre, és felkeresni és az egész évben nyitva tartó Vendéglő a Pisztrángoshoz éttermet, a látogatókat reggel 9 és délután 2 között fogadó Szent István- és Anna-barlangokat, ahol a látványos cseppkőformációk mellett telelő denevérekkel is találkozhatunk, valamint az ország legmagasabb, 20 méteres, mesterségesen kialakított vízesését és a zordon kinézetű újmassai Őskohót.</p>
                <img src="assets/lillafured.jpg" alt=""/>
                <h3 className={"mt-5"}>Egerszalóki sódomb</h3>
                <p>Hazánk egyik legkülönlegesebb természeti csodája az Eger határától 5 kilométerre lévő egerszalóki sódomb, melyhez hasonló képződmény mindössze két másik helyen fordul elő a világban, a Yellowstone Nemzeti Parkban és a törökországi Pamukkaléban. A sódomb, habár ősi eredetűnek látszik, valójában alig 60 éves: 1961-ben történt ugyanis, hogy szakemberek 400 méter mély lyukat fúrtak a térségben, kőolaj után kutatva, ám fekete arany helyett „mindössze” forró gyógyvizet találtak. Néhány évre rá megépítették az első medencét, majd a nyolcvanas években kifúrták a második kutat is, mára pedig hatalmas wellnessközpont nőtte körül a folyamatosan növekvő, 1200 négyzetméteres mészkőlerakódást.</p>
                <img src="assets/egerszalok.jpg" alt=""/>
                <h3 className={"mt-5"}>Kavicsos-tó</h3>
                <p>A Csepel-sziget méltatlanul alul helyezkedik el a budapestiek képzeletbeli kirándulóhely-listáján, holott a 257 négyzetkilométer területű sziget nincs híján természeti kincseknek, sem történelmi emlékeknek. Az ország legmagasabb építményének, a 314 méteres lakihegyi adótoronynak is otthonául szolgáló Szigetszentmiklós határában fekszik a drónfotósok és horgászok körében hatalmas népszerűségnek örvendő Kavicsos-tó és a magyar Maldív-szigeteknek is becézett, főként hétvégi házakkal beépített szigetrendszere. Hazánk sok más tavához hasonlóan a Kavicsos is mesterséges eredetű: nevét a helyén egykor működő bányáról kapta, ahol a panelházak építéséhez elengedhetetlen kavicsokat kotorták ki.</p>
                <img src="assets/kavicsos-to.jpg" alt=""/>
                <h3 className={"mt-5"}>Búbánatvölgy</h3>
                <p>Nevével ellentétben a Dunakanyar eme rejtett gyöngyszeme nem ad sok okot a bánkódásra: a Budapesttől nagyjából egyórás autóútra, a Szamár-hegy és a Hosszú-hegy között a Dunáig húzódó Búbánatvölgyet azok számára ajánljuk, akik egy kis csendre, a világ zajától való elvonulásra vágynak. A hangulatos horgásztavak láncára felfűzött természetes mélyedés könnyen megközelíthető, ezért rendkívül közkedvelt a túrázók között, és a közelben lévő, felújított játszótér miatt még a nagycsaládosok számára is kiváló kiránduló helyszín, de bográcsozásra és szalonnasütésre is van lehetőség. A P∆ és a P+ túrajelzéseket követve pazar panorámák, sűrű erdők és tóparti idill fogad minket.</p>
                <img src="assets/bubanatvolgy.jpg" alt=""/>
                <h3 className={"mt-5"}>Tatai Fényes Tanösvény</h3>
                <p>Tata télen is elragadóan bájos, 18 állomásos, másfél kilométer hosszú, cölöpökön nyugvó ökoturisztikai útvonala a vadregényes lápvidékre kalauzol el minket, megismertetve velünk a titokzatos láperdők és 20-22 Celsius fokos hőmérsékletű karsztforrások egyedülálló világát. A jelentős madárfaunával és növénytársulásokkal büszkélkedő vidéket fentről is megcsodálhatjuk a Nagy-Égeresben található kilátótorony tetejéről, illetve a kalandkedvelők kipróbálhatják a kézi erővel működtethető csónakot vagy a függőhidat is. A reggel 9 és délután 4 között látogatható Fényes tanösvény látogatóközpontja a helyi élővilágot bemutató gyerekbarát kiállításokkal teszi fel az i-re a pontot.</p>
                <img src="assets/tata.jpg" alt=""/>
                <h3 className={"mt-5"}>Derítő-tó</h3>
                <p>Ha már Tata, a város központjától néhány kilométerre keletre megbújó Derítő-tó mellett sem mehetünk el szó nélkül. A helyi szénbányák úszó üledékének felfogására 1961-ben létrehozott mesterséges állóvíz (hivatalos nevén Által-éri ülepítő) a kidőlt fák biztosította búvóhelyeknek köszönhetően ma igazi horgászparadicsom, melynek télen-nyáron halban gazdag vizét néhány éve a kirándulók is felfedezték maguknak. A 32 hektáros tó partján, valamint az északról a vízbe nyúló, a szárazfölddel egy keskeny stéggel összekötött szigetecskén romantikus faházak között andaloghatunk, gágogó és csicsergő madarak zenéjére, a téli fagy csengő dalára.</p>
                <img src="assets/derito-to.jpg" alt=""/>
                <h3 className={"mt-5"}>Cseszneki vár</h3>
                <p>A Valahol Európában forgatási helyszíneként is ismert, sziklaszirten magasodó cseszneki vár a Bakony egyik páratlan történelmi értéke. A tatárjárás után gótikus stílusban épült, hosszú és dicső életű vár eredetileg az Alföldről Győrbe vezető sóutat volt hivatott védelmezni, a török harcok idején végvárként teljesített szolgálatot, a 18. században pedig barokk ízlés szerint átépített várkastélyként az Esterházy hercegek egyik szálláshelyeként működött, mígnem egy földrengés és az azt követő tűzvész végleg megpecsételte sorsát. A Nemzeti Várprogram keretében részlegesen helyreállított, kutyabarát várból remek kilátás nyílik a hófödte Bakonyra, a 82-es főút melletti parkolóból pedig magára a várra.</p>
                <img src="assets/cseszneki-var.jpg" alt=""/>
                <h3 className={"mt-5"}>Csóványosi kilátó</h3>
                <p>Az izgalmakban és látványosságokban gazdag túraútvonalairól híres Börzsöny a téli hónapokban is ideális úti cél az aktív kikapcsolódás szerelmeseinek. Legmagasabb pontját a 938 méteres Csóványoson találjuk, melynek csúcsára mind a négy égtáj irányából felkapaszkodhatunk, hogy aztán az 1978-ban geodéziai mérőtoronyként felhúzott, közel 23 méter magas kilátóból levegő után kapkodva csodálkozzunk rá az elénk táruló, a Magas-Tátrától a Schneebergig terjedő, hegyvidéki körpanorámára. Ha ránk hallgattok, a csóványosi kilátót a Királyréthez közel eső Cseresznyefa parkolóból induló 6 órás körtúra keretében hódítjátok meg, amihez a részletes útvonalat megtaláljátok weboldalunkon, a 10 kihagyhatatlan látnivaló a télen is varázslatos Börzsönyben című cikkünkben.</p>
                <img src="assets/csovanyos.jpg" alt=""/>
                <h3 className={"mt-5"}>Kékestető</h3>
                <p>Ha van hely Magyarországon, ahová kötelező télen ellátogatni, az a 1014 méteres tengerszint feletti magasságával minden egyéb hazai hegycsúcsot maga mögé utasító Kékestető. A kristálytiszta hegyi levegője és különleges éghajlati jellemzői miatt 1963-ban klimatikus gyógyhellyé nyilvánított Kékestetőről indul az ország leghosszabb lesiklópályája, a síelők, snowboardosok és szánkózók legnagyobb örömére, míg messziről is jól látható, 180 méter magas tévétornya szó szerint lélegzetelállító kilátással kecsegtet az alattunk elterülő, ezüstösen csillogó, hófödte erdei útvonalakat rejtő tájra.</p>
                <img src="assets/kekesteto.jpg" alt=""/>
                <h3 className={"mt-5"}>Ilona-völgyi vízesés</h3>
                <p>A Keleti-Mátrában, az Ilona-patak által kivájt völgyben találjuk hazánk legmagasabb természetes zuhatagát: a 10 méter magasból, egy függőleges sziklafal V-alakú felső hasadékából vékony sugárban a mélybe zúduló víztömeget legkönnyebben a Parádfürdőtől induló zöldkör-turistajelzésen tudjuk megközelíteni: a kilencállomásos, 6,5 kilométer hosszú tanösvény százéves gesztenyefasor, a Rákóczi-fa, a Szent István-csevicekút és az Ördöggátak néven ismert miocén kori kőzetképződmény érintésével, a patakmeder vonalában haladva tárja elénk a jégbe fagyva még megkapóbb vízesés látványát.</p>
                <img src="assets/ilona-volgy.jpg" alt=""/>
                <h3 className={"mt-5"}>Sástó</h3>
                <p>Az elmúlt években a Fekete Zaj fesztivál helyszíneként a köztudatba bevonult mátrai Sástó az ország legmagasabban fekvő tava. A tó mellett egy 53 méter magas, olajfúrótoronyból kialakított kilátóból nézhetünk végig a tengerszint felett több mint 500 méterrel elterülő tájon, míg a környék természeti értékeivel megismerkedni kívánkozók a mátrafüredi Harkály Házhoz tartozó, 8 állomásos, 3,5 kilométer hosszú, Cincér tanösvény nevű túraútvonalat is bejárhatják. Sástó egykori kőbányájának területén 2014-ben nyílt meg az Oxygen Adrenalin Park, ami egy 930 méteres téli bobpályával és felejthetetlen élményekkel várja az egész családot.</p>
                <img src="assets/sasto.jpg" alt=""/>
                <p className={"mt-4"}><strong>Forrás:</strong> <a href="https://funzine.hu/2022/01/02/utazas/11-csodas-teli-kirandulohely-ha-magad-mogott-hagynad-a-varos-zajat/" target={"_blank"}>https://funzine.hu/2022/01/02/utazas/11-csodas-teli-kirandulohely-ha-magad-mogott-hagynad-a-varos-zajat/</a></p>
            </>

    }
    ,
    {
        id: 18, content:
            <>
                <h2>Hangolódj az Ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/wKhRnZZ0cJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/wKhRnZZ0cJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    }
    ,
    {
        id: 19, content:
            <>
                <h2>19. nap</h2>
                <div>
                    <a href="https://teams.microsoft.com/l/channel/19%3A065bee1b10e64344bf2aefc906ebec8d%40thread.tacv2/Minden ami motiv%C3%A1l?groupId=7b6bc839-d51a-4425-a8d6-eb83cf44d731&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78" target={"_blank"}><img src="assets/dec19.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/></a>
                    <a href="https://teams.microsoft.com/l/channel/19%3A065bee1b10e64344bf2aefc906ebec8d%40thread.tacv2/Minden ami motiv%C3%A1l?groupId=7b6bc839-d51a-4425-a8d6-eb83cf44d731&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78" target={"_blank"}><img src="assets/dec19-mob.jpg" alt="" className={'my-4 lg:hidden'}/></a>
                </div>
            </>
    }
    ,
    {
        id: 20, content:
            <>
                <h2>Karácsonyi asztali dísz termésekből</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/O8Ttdk5Xf6w"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/O8Ttdk5Xf6w"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <h4>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</h4>
            </>
    }
    ,
    {
        id: 21, content:
            <>
                <h2 className={'md:text-4xl text-center'}>Klári mézeskalács receptje</h2>
                <img src="assets/mezeskalacs.jpg" alt="" className={'mx-auto mb-4'}/>
                <p>A legtöbb házi készítésű receptnek meg van a maga története, személyessé az teszi, hogy a receptfüzetekben mellé írunk egy - egy kedves megjegyzést vagy személy nevét.</p>
                <p>Nekem ez a recept egy barátnőm óvodáskorú gyerekéhez kötődik, így közel 20 éves, még mindig, minden év karácsonyi időszakában elkészítem. Imádom az illatát, mosolygok, amikor elkezdem készíteni a családomnak és a barátaimnak.</p>
                <p>Fogadjátok szeretettel:</p>
                <p>1 kg liszthez hozzákeverünk 0,5 csomag szódabikarbónát és 1 csomag mézes kalács fűszerkeveréket (lehet ízlés szerint fahéj, szegfűszeg, kardamon, citromhéj keveréket is használni). A tálban lévő keverék közepébe helyezünk 25 dkg Rama margarint darabokban.</p>
                <p>35 dkg mézet és 25 dkg cukrot felforralunk majd a lisztbe helyezett margarin tetejére öntjük. Amikor a margarin felolvad 2 db tojássárgáját adunk hozzá, melegen összegyúrjuk. Az egynemű tésztát vékonyra nyújtjuk és formákat szaggatunk, amelyeket tojással megkenünk. Előmelegített sütőben néhány percig sütjük kb. 5-8 perc alatt elkészül.</p>
                <p>Az elkészült darabokat kihűlés követően dobozban tárolhatjuk, vagy díszíthetjük. Ehhez használhatunk tojásfehérje és kb. 25 dkg porcukor keverékéből készült keményhabot, amit habzsákból tudunk legegyszerűbben kinyomni.</p>
                <p>Biztos, hogy puha lesz, hosszan eltartható. Kávéhoz, teához önmagában bármikor bevetésre kész!</p>
            </>
    }
    ,
    {
        id: 22, content:
            <>
                <img src="assets/cicas.png" alt=""/>
            </>
    }
    ,
    {
        id: 23, content:
            <>
                <Game game={"HTML5/index.html"} title={""}
                      description={""}/>
            </>
    }
    ,
    {
        id: 24, content:
            <>
                <h2>24. nap</h2>
                <div>
                    <img src="assets/dec24.jpg" alt="" className={'my-4 rounded-lg hidden lg:block lg:max-w-[580px] 2xl:max-w-full'}/>
                    <img src="assets/dec24-mob.jpg" alt="" className={'my-4 lg:hidden'}/>
                </div>
            </>
    }
    ,
]
